import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {ChartFilter} from "@/models/DashboardModel"

export interface User {
  initials: string;
  firstname: string;
  surname: string;
  email: string;
  password: string;
  token: string;
  role: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

export interface EditMode {
  mode:number
}




@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  Chartfilter5 = {currentDashBoardId:0,unit:"Hour",startPeriod:new Date() ,endPeriod:new Date(),lastPoints:5} as ChartFilter
  dashboardEditModeId = {mode:0}

  /**
   * Get current chartEditModeId
   * @returns chartEditModeId
   */
  get dashboardEditMode(): EditMode {
  return this.dashboardEditModeId;
}

  /**
   * Get current user object
   * @returns User
   */
    get currentUser(): User {
    // if( this.user&& this.user.firstname&&this.user.surname)
    //   this.user.initials = this.user.firstname.substring(0,1)+this.user.surname.substring(0,1)    
    return this.user;
  }

  /**
   * Get current user object
   * @returns User
   */
   get chartFilter(): ChartFilter {
   return this.Chartfilter5;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
    get isUserAuthenticated(): boolean {
            
    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
      
      return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = ApiService.getModelStateErrors(error) as any 
    console.log(ApiService.getModelStateErrors(error))
    //this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    //console.log('setauth:');//todo geert
    //console.log(user);//todo geert
    
    this.isAuthenticated = true;
    this.user = user;
      this.errors = [];
     
    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
      //console.log('setuser:');//todo geert
      //console.log(user);//todo geert
    this.user = user;
  }

  @Mutation
  [Mutations.SET_DASHBOARDEDITMODE](id) {
    //console.log(id)
       this.dashboardEditModeId = id;
  }

  @Mutation
  [Mutations.SET_CHARTFILTER](chartFilter) {
      //console.log('setuser:');//todo geert
      //console.log(chartFilter);//todo geert
    this.Chartfilter5 = chartFilter;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
      this.errors = [];
      //console.log('purge auth');//todo geert
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
      console.log('start login:' + credentials);//todo geert
      return new Promise<void>((resolve, reject) => {
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
            //console.log('login response data:');//todo geert
            //console.log(data);
            //this.context.commit(Mutations.SET_AUTH, { name: null, surname: null, email: null, password: null, token: data.accessToken });//todo geert obj ipv data opgestuurd.
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
        })
        .catch(({ response }) => {
          console.log('errors' + response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    //console.log("start register")
    //console.log(credentials)
    return new Promise<void>((resolve, reject) => {
      
      ApiService.post("/customer/register", credentials)
        .then(({ data }) => {
            //onsole.log('create response data:' + data);//todo geert
            //this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          console.log('errors' + response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.ACTIVATION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/customer/accountactivation", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data); //todo geert wat moet hier?
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    this.context.commit(Mutations.PURGE_AUTH);
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/customer/passwordrecovery", payload)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data); //todo geert wat moet hier?
          this.context.commit(Mutations.PURGE_AUTH);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.NEW_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/customer/passwordrecoveryconfirm", payload)
        .then(({ data }) => {
          //this.context.commit(Mutations.SET_AUTH, data); //todo geert wat moet hier?
          this.context.commit(Mutations.PURGE_AUTH);
          resolve();
        })
        .catch(({ response }) => {
          console.log(response.data.errors);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
        ApiService.setHeader();
        //console.log('start verify' );//todo geert
      ApiService.get("/auth/verify")
          .then(({ data }) => {
              //console.log('verify response data:' + data);//todo geert
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
      return new Promise<void>((resolve, reject) => {
          console.log('update user start payload:' + payload);//todo geert
      ApiService.post("/auth/update_user", payload)
          .then(({ data }) => {
              //console.log('vupdate user response data:' + data);//todo geert
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
