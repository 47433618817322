import { createI18n } from "vue-i18n";
// import myTrans from "../../../public/trans.js";
// console.log("myTrans")
// console.log(myTrans)
const messages = {
  en: {
    PCBSerial: "PCB Serial",
    administration: "NostraBOX",
    templateAdministration: "Templates",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Craft",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Profile overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Account overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic flow",
    signIn: "Sign in",
    signUp: "Sign up",
    passwordReset: "Password reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private chat",
    groupChat: "Group chat",
    drawerChat: "Drawer chat",
    widgets: "Widgets",
    widgetsLists: "Widgets lists",
    widgetsStatistics: "Widgets statistics",
    widgetsCharts: "Widgets charts",
    widgetsMixed: "Widgets mixed",
    widgetsTables: "Widgets tables",
    widgetsFeeds: "Widgets feeds",
    changelog: "Change log",
    docsAndComponents: "Docs and components",
    megaMenu: "Mega menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite friends",
    viewUsers: "View users",
    upgradePlan: "Upgrade plan",
    shareAndEarn: "Share and earn",
    forms: "Forms",
    newTarget: "New target",
    newCard: "New card",
    newAddress: "New address",
    createAPIKey: "Create API key",
    twoFactorAuth: "Two factor authentication",
    createApp: "Create app",
    createAccount: "Create account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting started",
    customersListing: "Customers listing",
    customerDetails: "Customer details",
    masters: "Masters",
    NewMaster: "New master",
    Name: "Name",
    State: "State",
    Slaves: "Slaves",
    LastContact: "Last contact",
    Actions: "Actions",
    PublishMasterConfiguration: "Publish master configuration",
    EditMaster: "Edit master",
    DeleteMaster: "Delete master",
    NewSlave: "New slave",
    Expression: "Expression",
    devideBy: "Devide by",
    plus: "Plus",
    minus: "Minus",
    Multiplier: "Multiplier",
    Decimals: "Decimals",
    DataValue: "Data value",
    Exact: "Exact",
    Average: "Average",
    Change: "Change",
    Unit: "Unit",
    Submit: "Submit",
    MasterProperties: "Master properties",
    Serial: "Serial",
    Baudrate: "Baudrate",
    Send: "Send",
    Ping: "Ping",
    Delay: "Delay",
    Network: "Network",
    Cancel: "Cancel",
    PleaseWait: "Please wait",
    SlaveProperties: "Slave properties",
    Description: "Description",
    Station: "Station",
    Type: "Type",
    Protocol: "Protocol",
    IpAddress: "IP address",
    Port: "Port",
    Interval: "Interval",
    ChartProperties: "Chart properties",
    Charttype: "Chart type",
    SelectACharttype: "Select a chart type",
    Line: "Line",
    Column: "Column",
    Gauge: "Gauge",
    Displayorder: "Displayorder",
    Autoscale: "Autoscale",
    Min: "Min",
    Max: "Max",
    SlaveAddresses: "Slave addresses",
    NewAddress: "New address",
    AddressProperties: "Address properties",
    Address: "Address",
    Width: "Width",
    FirstOfUnit: "First of unit",
    LastOfUnit: "Last of unit",
    Multiply: "Multiply",
    switchStationNumber: "Are you sure to switch stationnumber?",
    usedBy: "Used by:",
    Lastpoints: "Last points",
    Startperiod: "Period start",
    Endperiod: "Period end",
    lastDataPoints: "Last data points",
    dateRange: "Data range",
    chartfilter: "Data filter",
    editMode: "Edit mode",
    language: "Language",
    signout: "Signout",
    Addresses: "Addresses",
    sortOrder: "Sort order",
    NewChart: "New chart",
    orderCharts: "Order charts",
    MoveUp: "Move up",
    MoveDown: "Move down",
    Graph: "Graph",
    Tab: "Tab",
    Graphs: "Graphs",
    Tabs: "Tabs",
    orderTabs: "Order tabs",
    alarmAdministration: "Alarm administration",
    Operator: "Operator",
    Duration: "Duration",
    Threshold: "Threshold",
    PublishAlarmConfiguration: "Publish alarm configuration",
    NewAlarm: "New alarm",
    Alarms: "Alarms",
    AlarmProperties: "Alarm properties",
    Acknowledge: "Acknowledge",
    Filter: "Filter",
    Alarm: "Alarm",
    Start: "Start",
    End: "End",
    Value: "Value",
    Slave: "Slave",
    States: "States",
    Count: "Count",
    Acknowledged: "Acknowledged",
    ExportCSV: "ExportCSV",
    ViewAll: "View all",
    ChartSeries: "Chart series",
    NewSerie: "New serie",
    Commands: "Commands",
    commandType: "commandtype",
    Command: "Command",
    Datagrid: "Datagrid",
    Absolute: "Absolute",
    NewCommand: "New command",
    WriteSlave: 'Write slave',
    ReadSlave: 'Read slave',
    On: 'On',
Off: 'Off',
  },
  nl: {
    PCBSerial: "Printplaat serienummer",
    administration: "NostraBOX",
    templateAdministration: "Templates",
    dashboard: "Dashboard",
    layoutBuilder: "Weergave bouwen",
    craft: "Ambacht",
    pages: "Paginas",
    profile: "Profiel",
    profileOverview: "Profiel overzicht",
    projects: "Projecten",
    campaigns: "Campagnes",
    documents: "Documenten",
    connections: "Connecties",
    wizards: "Wizards",
    horizontal: "Horizontaal",
    vertical: "Verticaal",
    account: "Account ",
    accountOverview: "Account overzicht",
    settings: "Instellingen",
    authentication: "Authenticatie",
    basicFlow: "Basisstroming",
    signIn: "Inloggen",
    signUp: "Inschrijven",
    passwordReset: "Wachtwoord resetten",
    error404: "Foutmelding 404",
    error500: "Foutmelding 500",
    apps: "Applicatie",
    chat: "Chat",
    privateChat: "Prive chat",
    groupChat: "Groep chat",
    drawerChat: "Drawer chat",
    widgets: "Widgets",
    widgetsLists: "Widgets lijst",
    widgetsStatistics: "Widgets statistieken",
    widgetsCharts: "Widgets grafieken",
    widgetsMixed: "Widgets gemengd",
    widgetsTables: "Widgets tabellen",
    widgetsFeeds: "Widgets feeds",
    changelog: "Log wijzigen",
    docsAndComponents: "Docs en componenten",
    megaMenu: "Mega menu",
    exampleLink: "Voorbeeldlink",
    modals: "Modaliteiten",
    general: "Algemeen",
    inviteFriends: "Vrienden uitnodigen",
    viewUsers: "Bekijk gebruikers",
    upgradePlan: "Opwaardeer plan",
    shareAndEarn: "Deel en verdien",
    forms: "Formulieren",
    newTarget: "Nieuw doelwit ",
    newCard: "Nieuwe kaart",
    newAddress: "Nieuw adres",
    createAPIKey: "API sleutel aanmaken",
    twoFactorAuth: "Tweefactorauthenticatie",
    createApp: "Applicatie aanmaken",
    createAccount: "Account aanmaken",
    documentation: "Documentatie",
    components: "Componenten",
    resources: "Bronnen",
    activity: "Activiteiten",
    customers: "Klanten",
    gettingStarted: "Beginnen",
    customersListing: "Klantenlijst",
    customerDetails: "Klantendetails",
    masters: "Masters",
    NewMaster: "Niewe master",
    Name: "Naam",
    State: "Staat",
    Slaves: "Slaves ",
    LastContact: "Laatste contact",
    Actions: "Acties",
    PublishMasterConfiguration: "Publiceer masterconfiguratie",
    EditMaster: "Bewerking",
    DeleteMaster: "Verwijdering",
    NewSlave: "Nieuwe slave",
    Expression: "Uitdrukking ",
    devideBy: "Delen door",
    plus: "Plus",
    minus: "Min ",
    Multiplier: "Vermenigvuldiger",
    Decimals: "Decimalen",
    DataValue: "Gegevenswaarde",
    Exact: "Exact",
    Average: "Gemiddeld",
    Change: "Verandering ",
    Unit: "Eenheid",
    Submit: "Indienen",
    MasterProperties: "Hoofdeigenschappen",
    Serial: "Serieel",
    Baudrate: "Modulatiesnelheid",
    Send: "Versturen",
    Ping: "Ping",
    Delay: "Vertraging",
    Network: "Netwerk",
    Cancel: "Annuleren",
    PleaseWait: "Wacht alstublieft",
    SlaveProperties: "Slave verhoudingen",
    Description: "Beschrijving",
    Station: "Station",
    Type: "Type",
    Protocol: "Protocol",
    IpAddress: "IP adres",
    Port: "Poort",
    Interval: "Interval",
    ChartProperties: "Grafiek verhoudingen",
    Charttype: "Grafiek type",
    SelectACharttype: "Selecteer een grafiek type",
    Line: "Lijn",
    Column: "Kolom",
    Gauge: "Graadmeter",
    Displayorder: "Weergavevolgorde",
    Autoscale: "Automatisch aanpassen",
    Min: "Minimaal",
    Max: "Maximaal",
    SlaveAddresses: "Slave adressen",
    NewAddress: "Nieuw adres",
    AddressProperties: "Adres eigenschappen",
    Address: "Adres ",
    Width: "Breedte",
    FirstOfUnit: "Eerste eenheid",
    LastOfUnit: "Laatste eenheid",
    Multiply: "Vermenigvuldigen",
    switchStationNumber: "Weet je zeker dat je van station nummer wisselt?",
    usedBy: "Gebruikt door:",
    Lastpoints: "Laatste punten",
    Startperiod: "Startdatum",
    Endperiod: "Einddatum",
    lastDataPoints: "Laatste data punten",
    dateRange: "Datum spanne",
    chartfilter: "Data filter",
    editMode: "Bewerk modus",
    language: "Taal",
    signout: "Afmelden",
    Addresses: "Addressen",
    sortOrder: "Volgorde",
    NewChart: "Nieuwe grafiek",
    orderCharts: "Volgorde grafieken",
    MoveUp: "Omhoog",
    MoveDown: "Omlaag",
    Graph: "Grafiek",
    Tab: "Tab",
    Graphs: "Grafieken",
    Tabs: "Tabs",
    orderTabs: "Volgorde tabs",
    alarmAdministration: "Alarm administaratie",
    Operator: "Operator",
    Duration: "Duur",
    Threshold: "Grenswaarde",
    PublishAlarmConfiguration: "Publish alarm configuratie",
    NewAlarm: "Nieuw alarm",
    Alarms: "Alarmen",
    AlarmProperties: "Alarm eigenschappen",
    Acknowledge: "Afmelden",
    Filter: "Filter",
    Alarm: "Alarm",
    Start: "Start",
    End: "Einde",
    Value: "Waarde",
    Slave: "Slave",
    States: "Statussen",
    Count: "Aantal",
    Acknowledged: "Afgemeld",
    ExportCSV: "ExportCSV",
    ViewAll: "View all",
    ChartSeries: "Chart series",
    NewSerie: "New serie",
    Commands: "Commands",
    commandType: "commandtype",
    Command: "Command",
    Datagrid: "Datagrid",
    Absolute: "Absolute",
    NewCommand: "New command",
    WriteSlave: 'Write slave',
    ReadSlave: 'Read slave',
    On: 'On',
    Off: 'Off',
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
