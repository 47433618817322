import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import * as signalR from "@microsoft/signalr";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;
  public static srConnection: signalR.HubConnection;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    //const baseUrl = "https://nopdev.benp.nl:16006"
    //const baseUrl = "https://localhost:44369";
    const baseUrl = ""
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = baseUrl+ "/api";

    //var koe = JwtService.getToken();
    
    ApiService.srConnection = new signalR.HubConnectionBuilder()
    .withUrl( baseUrl+"/chartdatahub", {
        accessTokenFactory: () =>
         // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOiIxNjQ2MDM4NjQ5IiwiZXhwIjoiMTk2MTM5ODY0OSIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL2VtYWlsYWRkcmVzcyI6ImRvbGZAYmVucC5ubCIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL25hbWVpZGVudGlmaWVyIjoiYWY0NzY4ZDctYmE0NS00MjQ5LTgwMGUtMDAyM2U3YzQ3Yjk2IiwiaHR0cDovL3NjaGVtYXMueG1sc29hcC5vcmcvd3MvMjAwNS8wNS9pZGVudGl0eS9jbGFpbXMvbmFtZSI6ImRvbGZAYmVucC5ubCIsIkN1c3RvbWVySWQiOiI1In0.hbOhZrn8tdJzlNCBVM4hxnuRtJGQHtfhcu1ZaPxiyJ0"
          //JwtService.getToken()?.
        //JwtService.getToken() as string
        {
        const token = JwtService.getToken() as string;
        return token;
        }
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
      ApiService.srConnection.start().catch((err) => alert(err));  //todo Geert
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
      ] = `Bearer ${JwtService.getToken()}`;//todo geert Bearer ipv Token  toegevoegd voor Dolf
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */  
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios
      .get(`${resource}/${slug}`)
      .catch((error) => {
        throw new Error(`[KT] ApiService ${error}`);
      });
  }

   /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
    public static get2(//todo geert waarom wordt er /{slug} achter gezet??
      resource: string,
      slug = "" as string
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios
        .get(`${resource}`)
        .catch((error) => {
          throw new Error(`[KT] ApiService ${error}`);
        });
    }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.delete(resource).catch((error) => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }

  public static getModelStateErrors(errorText) {  //todo geert moet deze hier?
    
    const errorList: Array<string> = [];
      
    if (errorText != null) {
        // Extract keys from the ModelState portion
        for (const key in errorText) {
          for (const key2 in errorText[key]) {
               errorList.push(errorText[key][key2]);
          }
        }
    }
    return errorList;
    
  }
  //deze geeft 1 string terug
  public static getModelStateError(errorText) {  
    const errorList=this.getModelStateErrors(errorText)  
    let errorString = ""
    errorList.map((item)=>{errorString += item})
    //return errorList;
    return errorString
  }


}

export default ApiService;
