import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import Dashboard from "@/models/DashboardModel"
export interface DashboardInfo {
  errors: Array<string>;
  dashboards: Dashboard[]
  
}


@Module
export default class DashboardModule extends VuexModule implements DashboardInfo {
  errors = [];
  dashboards = [] as Array<Dashboard>;
  a = "aap"

  masterId = 0
  slaveId = 0
  addressId = 0
  slaveTemplateId = 0
  addressTemplateId = 0
  
  get curMasterId(): number {
    //console.log("getting masterId"+this.masterId)
    return this.masterId;
  }
  get curSlaveId(): number {
    return this.slaveId;
  }
  get curAddressId(): number {
    console.log("getting addressId"+this.addressId)
    return this.addressId;
  }
  get curSlaveTemplateId(): number {
    return this.slaveTemplateId;
  }
  get curAddressTemplateId(): number {
    return this.addressTemplateId;
  }

  /**
   * Get all dashboards
   * @returns Dashboard[]
   */
    get allDashboards(): Dashboard[] {
        
    return this.dashboards;
  }

  get test5():string {
       
    return this.a;
  }

  /**
   * Get  errors
   * @returns array
   */
  get getErrors2(): Array<string> {
      
      return this.errors;
  }

  @Mutation
  [Mutations.SET_TEST5](t) {
      
    this.a = t;
  }

  @Mutation
  [Mutations.SET_ERROR2](error) {
      
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_DASHBOARDS](dashboards) {
      console.log('setdashboards:' + dashboards);//todo geert
    this.dashboards = dashboards;
  }

  @Mutation
  [Mutations.SET_MASTER_ID](masterId) {
      
    this.masterId = masterId;
  }

  @Mutation
  [Mutations.SET_SLAVE_ID](slaveId) {
      
    this.slaveId = slaveId;
  }

  @Mutation
  [Mutations.SET_ADDRESS_ID](addressId) {
    console.log('set addressId to:'+addressId)  
    this.addressId = addressId;
  }

  @Mutation
  [Mutations.SET_ADDRESS_TEMPLATE_ID](addressTemplateId) {
      
    this.addressTemplateId = addressTemplateId;
  }

  @Mutation
  [Mutations.SET_SLAVE_TEMPLATE_ID](slaveTemplateId) {
      
    this.slaveTemplateId = slaveTemplateId;
  }


/*   @Action
  [Actions.GET_DASHBOARDS2]() {
    if (JwtService.getToken()) {
        ApiService.setHeader();
        console.log('start getdashboards' );//todo geert
      ApiService.get("/test/dashboard")
          .then(({ data }) => {
              console.log('getdashboards response data:' + data);//todo geert
          this.context.commit(Mutations.SET_DASHBOARDS, data);
          
        })
        .catch(({ response }) => {
          console.log('getdashboards error');//todo geert
          this.context.commit(Mutations.SET_ERROR2, response.data.errors);
        });
    } else {
      //this.context.commit(Mutations.PURGE_AUTH);
    }
  } */

  @Action
  [Actions.GET_DASHBOARDS]() {
    ApiService.setHeader();//todo geert is dit nodig?
    return new Promise<void>((resolve, reject) => {
      ApiService.get("/test/dashboard")
        .then(({ data }) => {
            console.log('create response data:' + {data});//todo geert
            this.context.commit(Mutations.SET_DASHBOARDS, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR2, response.data.errors);
          reject();
        });
    });
  }

}
